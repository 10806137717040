import {
  FETCH_CLIENT_TAGS,
  FETCH_CLIENT_TAGS_SUCCESS,
  FETCH_CLIENT_TAGS_ERROR,
  UPDATE_CLIENT_TAG,
  UPDATE_CLIENT_TAG_SUCCESS,
  UPDATE_CLIENT_TAG_ERROR,
  CREATE_CLIENT_TAG,
  CREATE_CLIENT_TAG_SUCCESS,
  CREATE_CLIENT_TAG_ERROR,
  DELETE_CLIENT_TAG,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  FETCH_CLIENTS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
  DELETE_CLIENT,
  UPDATE_CLIENT
} from '../actions';

export const loadClientTags = (params) => ({
  type: FETCH_CLIENT_TAGS,
  payload: { page: params.page, type: 1 }
});
export const loadSaleTags = (params) => ({
  type: FETCH_CLIENT_TAGS,
  payload: { page: params.page, type: 2 }
});
export const loadClientTagsSuccess = (list, page, total, size) => ({
  type: FETCH_CLIENT_TAGS_SUCCESS,
  payload: { list: list, page: page, total: total, size: size }
});
export const loadClientTagsError = (message) => ({
  type: FETCH_CLIENT_TAGS_ERROR,
  payload: { message }
});

export const updateClientTag = (params, page, type) => ({
  type: UPDATE_CLIENT_TAG,
  payload: { params, page, type}
});

export const deleteClientTag = (params, page, type) => ({
  type: DELETE_CLIENT_TAG,
  payload: { params, page, type}
});

export const addClientTag = (params, page) => ({
  type: CREATE_CLIENT_TAG,
  payload: { params, page }
});

export const createClient = (data, history, from) => ({
  type: CREATE_CLIENT,
  payload: { data, history, from }
});
export const createClientSuccess = (user) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: user
});
export const createClientError = (message) => ({
  type: CREATE_CLIENT_ERROR,
  payload: { message }
});

export const loadClients = (params) => ({
  type: FETCH_CLIENTS,
  payload: { ...params }
});
export const loadClientsSuccess = (list, page, total, size) => ({
  type: FETCH_CLIENTS_SUCCESS,
  payload: { list: list, page: page, total: total, size: size }
});
export const loadClientsError = (message) => ({
  type: FETCH_CLIENTS_ERROR,
  payload: { message }
});

export const deleteClient = (params, page) => ({
  type: DELETE_CLIENT,
  payload: { params, page }
});

export const updateClient = (data, history, from, gauge, showPopup) => ({
  type: UPDATE_CLIENT,
  payload: { data, history, from, gauge, showPopup}
});