import {
 SAVE_SEGMENT,
 SAVE_HEADER_DATA,
 SAVE_VOCAL_NEW_MESSAGE_DATA,
 SAVE_VOCAL_IMPORT_MESSAGE,
 GET_VOICE_MESSAGE,
 GET_VOICE_MESSAGE_SUCCESS,
 GET_VOICE_MESSAGE_ERROR,
 GET_AUDIO_MESSAGE,
 GET_AUDIO_MESSAGE_SUCCESS,
 GET_AUDIO_MESSAGE_ERROR,
 DELETE_VOICE_MESSAGE,
 DELETE_VOICE_MESSAGE_SUCCESS,
 DELETE_VOICE_MESSAGE_ERROR,
 UPDATE_MESSAGE_TITLE,
 UPDATE_MESSAGE_TITLE_SUCCESS,
 UPDATE_MESSAGE_TITLE_ERROR,
 GET_MESSAGE_CODE,
 GET_MESSAGE_CODE_SUCCESS,
 GET_MESSAGE_CODE_ERROR,
 GET_BUDGET,
 GET_BUDGET_SUCCESS,
 GET_BUDGET_ERROR


} from '../actions'


export const save_segment = ( segment )  => ({
	type: SAVE_SEGMENT,
	payload: segment
})

export const save_header_data = (data)  => ({
	type: SAVE_HEADER_DATA,
	payload: {data}
})  

export const save_vocal_new_message_data = (data)  => ({
	type: SAVE_VOCAL_NEW_MESSAGE_DATA,
	payload: {data}
})
export const save_vocal_import_message = (data)  => ({
	type: SAVE_VOCAL_IMPORT_MESSAGE,
	payload: {data}
})


export const get_voice_message = (params)  => ({
	type: GET_VOICE_MESSAGE,
	payload: { page: params.page }
});


export const get_voice_message_success = (list, page, total, size)  => ({
	type: GET_VOICE_MESSAGE_SUCCESS,
	payload: { list: list, page: page, total: total, size: size }
});

export const get_voice_message_error = (message) => ({
  type: GET_VOICE_MESSAGE_ERROR,
  payload: { message }
});

export const get_audio_message = (data) => ({
  type: GET_AUDIO_MESSAGE,
  payload: { data }
});

export const get_audio_message_success = (data) => ({
  type: GET_AUDIO_MESSAGE_SUCCESS,
  payload: data 
});

export const get_audio_message_error = (message) => ({
  type: GET_AUDIO_MESSAGE_SUCCESS,
  payload: { message }
});



export const delete_voice_message = (id)  => ({
	type: DELETE_VOICE_MESSAGE,
	payload: { id }
});


export const delete_voice_message_success = (data)  => ({
	type: DELETE_VOICE_MESSAGE_SUCCESS,
	payload: {data}
});

export const delete_voice_message_error = (message) => ({
  type: DELETE_VOICE_MESSAGE_ERROR,
  payload: { message }
});


export const update_message_title = (data)  => ({
	type: UPDATE_MESSAGE_TITLE,
	payload: {data}
});


export const update_message_title_success = (data)  => ({
	type: UPDATE_MESSAGE_TITLE_SUCCESS,
	payload: {data}
});

export const update_message_title_error = (message) => ({
  type: UPDATE_MESSAGE_TITLE_ERROR,
  payload: { message }
});


export const get_message_code = (params)  => ({
	type: GET_MESSAGE_CODE,
	payload: params
});


export const get_message_code_success = (data)  => ({
	type: GET_MESSAGE_CODE_SUCCESS,
	payload: {data}
});

export const get_message_code_error = (message) => ({
  type: GET_MESSAGE_CODE_ERROR,
  payload: { message }
});

export const get_budget = (params)  => ({
	type: GET_BUDGET,
	payload: params
});


export const get_budget_success = (data)  => ({
	type: GET_BUDGET_SUCCESS,
	payload: {data}
});

export const get_budget_error = (message) => ({
  type: GET_BUDGET_ERROR,
  payload: { message }
});

