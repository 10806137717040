import { combineReducers } from 'redux';
import auth from './auth/reducer';
import user from './user/reducer';
import news from './news/reducer';
import client from './client/reducer';
import sale from './sales/reducer';
import segment from './segment/reducer';
import campagnes from './campagnes/reducer';
import checques from './checques/reducer';
import segmentActions from './segmentActions/reducer';
import smsVocalCampagnes from './smsVocalCampagnes/reducer';
import globals from './globals/reducer';

const reducers = combineReducers({
  auth,
  user,
  globals,
  news,
  client,
  sale,
  segment,
  campagnes,
  checques,
  segmentActions,
  smsVocalCampagnes
});

export default reducers;