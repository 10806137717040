
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    FETCH_NEWS
} from '../actions';

import {
  loadNewsSuccess,
  loadNewsError,
  showLoader,
  hideLoader
} from '../actions';

import {
  getNewsRequest,
  getHomeNewsRequest
} from '../../services/api';

import { 
  showError,
  showInfo,
  showSuccess,
  showWarning 
} from '../../services/utils';

function* getNewsAsync() {
    try {

      yield put(showLoader());
      const response = yield call(getHomeNewsRequest);
      if(response && response['hydra:member']) {
        yield put(hideLoader());
        yield put(loadNewsSuccess(response['hydra:member']));
        // showSuccess('Félicitations!', 'Your account has been successfully created!');
      }
    } 
    catch (error) {
      yield put(hideLoader());
      yield put(loadNewsError(error['hydra:description']));
      // console.log(error)
      // showError('Sorry!', error['hydra:description']);
      // yield put(createUserError(error['hydra:description']));
    }
}

export function* watchLoadNews() {
    yield takeEvery(FETCH_NEWS, getNewsAsync);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoadNews)
    ]);
}