import {
  FETCH_NEWS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_ERROR
} from '../actions';

export const loadNews = () => ({
  type: FETCH_NEWS,
  payload: {}
});
export const loadNewsSuccess = (news) => ({
  type: FETCH_NEWS_SUCCESS,
  payload: news
});
export const loadNewsError = (message) => ({
  type: FETCH_NEWS_ERROR,
  payload: { message }
});
