import {
	SAVE_SEGMENT,
 	SAVE_HEADER_DATA,
    SAVE_VOCAL_NEW_MESSAGE_DATA,  
    SAVE_VOCAL_IMPORT_MESSAGE,
    GET_VOICE_MESSAGE,
    GET_VOICE_MESSAGE_SUCCESS,
    GET_VOICE_MESSAGE_ERROR,
    VOICE_MESSAGE_PAGE_SIZE,
    GET_AUDIO_MESSAGE,
    GET_AUDIO_MESSAGE_SUCCESS,
    GET_AUDIO_MESSAGE_ERROR,
    DELETE_VOICE_MESSAGE,
    DELETE_VOICE_MESSAGE_SUCCESS,
    DELETE_VOICE_MESSAGE_ERROR,
    UPDATE_MESSAGE_TITLE,
    UPDATE_MESSAGE_TITLE_SUCCESS,
    UPDATE_MESSAGE_TITLE_ERROR,
    GET_MESSAGE_CODE,
    GET_MESSAGE_CODE_SUCCESS,
    GET_MESSAGE_CODE_ERROR,
    GET_BUDGET,
    GET_BUDGET_SUCCESS,
    GET_BUDGET_ERROR
} from '../actions';

const INIT_STATE = {
	segment: '',
	headerData: '',
    vocalnewMessage: {},    
    importMessageData: {},
    voiceMessageInfo:{
      list: [],
      total: 0,
      page: 1,
      size: VOICE_MESSAGE_PAGE_SIZE
    },
    audio_messsage:{},
    code: null,
    budget: null
};

export default (state = INIT_STATE, action) => {
    // console.log(action.payload)
    switch (action.type) {
        case SAVE_SEGMENT:
            return { ...state, segment: action.payload};
        case SAVE_HEADER_DATA:
            return { ...state, headerData: action.payload};
        case SAVE_VOCAL_NEW_MESSAGE_DATA:
            return { ...state, vocalnewMessage: action.payload};        
            return { ...state, headerData: action.payload};    
        case SAVE_VOCAL_IMPORT_MESSAGE:
            return { ...state, importMessageData: action.payload}; 
        case GET_VOICE_MESSAGE:
            return { ...state,  };
        case GET_VOICE_MESSAGE_SUCCESS:
            return { ...state, voiceMessageInfo: action.payload};
        case GET_VOICE_MESSAGE_ERROR:
            return { ...state, };
        case GET_AUDIO_MESSAGE:
            return {...state, }
        case GET_AUDIO_MESSAGE_SUCCESS:
            return {...state, audio_messsage: action.payload }
        case GET_AUDIO_MESSAGE_ERROR:
            return {...state, }
        case DELETE_VOICE_MESSAGE:
            return {...state, }
        case DELETE_VOICE_MESSAGE_SUCCESS:
            return {...state, audio_messsage: action.payload }
        case DELETE_VOICE_MESSAGE_ERROR:
            return {...state, }
        case UPDATE_MESSAGE_TITLE:
            return {...state, }
        case UPDATE_MESSAGE_TITLE_SUCCESS:
            return {...state, }
        case UPDATE_MESSAGE_TITLE_ERROR:
            return {...state, }
        case GET_MESSAGE_CODE:
            return {...state, }
        case GET_MESSAGE_CODE_SUCCESS:
            return {...state, code: action.payload}
        case GET_MESSAGE_CODE_ERROR:
            return {...state, }

        case GET_BUDGET:
            return {...state, }
        case GET_BUDGET_SUCCESS:
            return {...state, budget: action.payload}
        case GET_BUDGET_ERROR:
            return {...state, }                        
        default: return { ...state };
    }
}