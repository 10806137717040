import {
  CREATE_CAMPAGNE,
  CREATE_CAMPAGNE_SUCCESS,
  CREATE_CAMPAGNE_ERROR,
  FETCH_CAMPAGNES,
  CAMPAGNE_PAGE_SIZE,
  PAGE_SIZE,
  FETCH_CAMPAGNES_SUCCESS,
  FETCH_CAMPAGNES_ERROR,
  DELETE_CAMPAGNE,
  UPDATE_CAMPAGNE,
  DUPLICATE_CAMPAGNE,
  SAVE_SEGMENT_FOR_SMS,
  UPDATE_SEGMENT_FOR_SMS,
  SAVE_SEGMENT_PROPS_FOR_SMS,
  SMS_CAMPAGNE_FORM_DATA
} from '../actions';

const INIT_STATE = {
    campagnesPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CAMPAGNE_PAGE_SIZE
    },
    typeProps: '',
    segment: '',
    smsCampagneForm: '',
  };

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CAMPAGNES:
            return { ...state,  };
        case FETCH_CAMPAGNES_SUCCESS:
            return { ...state, campagnesPageInfo: action.payload};
        case FETCH_CAMPAGNES_ERROR:
            return { ...state, };
        case SAVE_SEGMENT_FOR_SMS:
            return { ...state, segment: action.payload };
        case SAVE_SEGMENT_PROPS_FOR_SMS:
            return { ...state, typeProps: action.payload };
        case SMS_CAMPAGNE_FORM_DATA:
            return { ...state, smsCampagneForm: action.payload };
        default: return { ...state };
    }
}