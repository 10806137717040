// import moment from 'moment'
import { Modal } from 'antd';
import moment from 'moment';

export const verifyResponse = (response) => {
    // console.log(response)
    // return response;
    return response.ok ? response : Promise.reject(response);
};

export const handleError = (error) =>
    error.json().then((json) => Promise.reject(json));
// Promise.reject(error);

// Show error modal
export const showError = (title = '', content = '', time = 0) => {
    console.log(title);
    console.log(content);
    console.log(time);
    if (time) {
        let secondsToGo = time;
        const modal = Modal.error({
            title: title,
            centered: true,
            content: `${content} ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `${content} ${secondsToGo} second.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.error({
            title: title,
            content: content,
        });
    }
};

// Show info modal
export const showInfo = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.info({
            title: title,
            // centered: true,
            content: `${content} ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `${content} ${secondsToGo} second.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.info({
            title: title,
            content: content,
        });
    }
};

// Show warning modal
export const showWarning = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.warning({
            title: title,
            // centered: true,
            content: `${content}`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                okText: `${secondsToGo}s  Ok`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.warning({
            title: title,
            content: content,
        });
    }
};

// Show success modal
export const showSuccess = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.success({
            title: title,
            // centered: true,
            content: `${content}`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                okText: `${secondsToGo}s  Ok`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.success({
            title: title,
            content: content,
        });
    }
};

export const isPublicHoliday = (date) => {
    // console.log(date.format('M'));
    // console.log(date.format('D'));
    let isPublic = false;
    let month = date.format('M');
    let day = date.format('D');

    if (month == 1) {
        if (day == 1) {
            isPublic = true;
        }
    } else if (month == 4) {
        if (day == 13) {
            isPublic = true;
        }
    } else if (month == 5) {
        if (day == 1) {
            isPublic = true;
        } else if (day == 8) {
            isPublic = true;
        } else if (day == 21) {
            isPublic = true;
        }
    } else if (month == 6) {
        if (day == 1) {
            isPublic = true;
        }
    } else if (month == 7) {
        if (day == 14) {
            isPublic = true;
        }
    } else if (month == 8) {
        if (day == 15) {
            isPublic = true;
        }
    } else if (month == 11) {
        if (day == 1) {
            isPublic = true;
        } else if (day == 11) {
            isPublic = true;
        }
    } else if (month == 12) {
        if (day == 25) {
            isPublic = true;
        }
    }

    return isPublic;
};

const y = [
    '1/01/2020',
    '13/4/2020',
    '1/5/2020',
    '8/5/2020',
    '21/5/2020',
    '1/6/2020',
    '14/7/2020',
    '25/12/2020',
    '15/8/2020',
    '1/11/2020',
    '11/11/2020',
    //2021
    '01/01/2021',
    '05/04/2021',
    '01/05/2021',
    '08/05/2021',
    '13/05/2021',
    '24/05/2021',
    '14/07/2021',
    '15/08/2021',
    '01/11/2021',
    '11/11/2021',
    '25/12/2021',
    //2022
    '01/01/2022',
    '18/04/2022',
    '01/05/2022',
    '08/05/2022',
    '26/05/2022',
    '06/06/2022',
    '14/07/2022',
    '15/08/2022',
    '01/11/2022',
    '11/11/2022',
    '25/12/2022',
    // 2023
    '01/01/2023',
    '10/04/2023',
    '01/05/2023',
    '08/05/2023',
    '18/05/2023',
    '29/05/2023',
    '14/07/2023',
    '15/08/2023',
    '01/11/2023',
    '11/11/2023',
    '25/12/2023',
    // 2024
    '01/01/2024',
    '01/04/2024',
    '01/05/2024',
    '08/05/2024',
    '09/05/2024',
    '20/05/2024',
    '14/07/2024',
    '15/08/2024',
    '01/11/2024',
    '11/11/2024',
    '25/12/2024',
    // 2025
    '01/01/2025',
    '21/04/2025',
    '01/05/2025',
    '08/05/2025',
    '29/05/2025',
    '09/06/2025',
    '14/07/2025',
    '15/08/2025',
    '01/11/2025',
    '11/11/2025',
    '25/12/2025',
];
const holidays = y.map((d) => moment(d, 'DD/MM/YYYY').format('DD/MM/YYYY'));

export const getPublicHolidays = (year, country) => {
    return holidays;
};
