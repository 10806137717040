import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import newsSagas from './news/saga';
import clientSagas from './client/saga';
import saleSagas from './sales/saga';
import segmentSagas from './segment/saga';
import campagnesSagas from './campagnes/saga';
import checquesSagas from './checques/saga';
import actionSegmentSaga from './segmentActions/saga';
import smsVocalCampagnesSaga from './smsVocalCampagnes/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    newsSagas(),
    clientSagas(),
    saleSagas(),
    segmentSagas(),
    campagnesSagas(),
    checquesSagas(),
    actionSegmentSaga(),
    smsVocalCampagnesSaga()
  ]);
}
