import {
  FETCH_ACTIONS_SEGMENTS,
  FETCH_ACTIONS_SEGMENTS_SUCCESS,
  FETCH_ACTIONS_SEGMENTS_ERROR,
} from '../actions';

const INIT_STATE = {
      list: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ACTIONS_SEGMENTS:
            return { ...state,  };
        case FETCH_ACTIONS_SEGMENTS_SUCCESS:
            return { ...state, list: action.payload};
        case FETCH_ACTIONS_SEGMENTS_ERROR:
            return { ...state, };
        default: return { ...state };
    }
}