import {
    CREATE_CAMPAGNE,
    CREATE_CAMPAGNE_SUCCESS,
    CREATE_CAMPAGNE_ERROR,
    FETCH_CAMPAGNES,
    FETCH_CAMPAGNES_SUCCESS,
    FETCH_CAMPAGNES_ERROR,
    DELETE_CAMPAGNE,
    UPDATE_CAMPAGNE,
    DUPLICATE_CAMPAGNE,
    SAVE_SEGMENT_FOR_SMS,
    UPDATE_SEGMENT_FOR_SMS,
    SAVE_SEGMENT_PROPS_FOR_SMS,
    SMS_CAMPAGNE_FORM_DATA,
} from '../actions';

// creating Campaign SendingMode Enums;
export const CAMPAIGN_SENDING_MODES = ['differe', 'immediat', 'brouillon'];

export const createCampagne = (data, history, from) => ({
    type: CREATE_CAMPAGNE,
    payload: { data, history, from },
});
export const duplicateCampagne = (data, page) => ({
    type: DUPLICATE_CAMPAGNE,
    payload: { data, page },
});
export const createCampagneSuccess = (user) => ({
    type: CREATE_CAMPAGNE_SUCCESS,
    payload: user,
});
export const creatSCampagneError = (message) => ({
    type: CREATE_CAMPAGNE_ERROR,
    payload: { message },
});

export const loadCampagnes = (params) => ({
    type: FETCH_CAMPAGNES,
    payload: { page: params.page },
});
export const loadCampagnesSuccess = (list, page, total, size) => ({
    type: FETCH_CAMPAGNES_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});
export const loadCampagnesError = (message) => ({
    type: FETCH_CAMPAGNES_ERROR,
    payload: { message },
});

export const deleteCampagne = (params, page) => ({
    type: DELETE_CAMPAGNE,
    payload: { params, page },
});

export const updateCampagne = (data, history, from) => ({
    type: UPDATE_CAMPAGNE,
    payload: { data, history, from },
});

export const save_segment_for_sms = (segment) => ({
    type: SAVE_SEGMENT_FOR_SMS,
    payload: segment,
});
export const segment_props_for_sms = (segProps) => ({
    type: SAVE_SEGMENT_PROPS_FOR_SMS,
    payload: segProps,
});

export const sms_campagne_form_data = (payload) => ({
    type: SMS_CAMPAGNE_FORM_DATA,
    payload: payload,
});
