import {
    FETCH_SALE_TAGS,
    FETCH_SALE_TAGS_SUCCESS,
    FETCH_SALE_TAGS_ERROR,
    PAGE_SIZE,
    SALE_TAGS_PAGE_SIZE,
    FETCH_SALES,
    FETCH_SALES_SUCCESS,
    FETCH_SALES_ERROR,
    FETCH_DASHBOARD_SALES,
    FETCH_DASHBOARD_SALES_SUCCESS,
    FETCH_DASHBOARD_SALES_ERROR,
    SALES_PAGE_SIZE,
    SET_SALE_ACTIVE_CLIENT,
    SET_SALE_ACTIVE_AMOUNT,
    SET_SALE_ACTIVE_TAG,
    SET_SALE_PAGE_PAGINATION,
} from '../actions';

const INIT_STATE = {
    // saleTags: [],
    saleTagsPageInfo: {
        list: [],
        total: 0,
        page: 1,
        size: SALE_TAGS_PAGE_SIZE,
    },
    salesPageInfo: {
        list: [],
        total: 0,
        page: 1,
        size: SALES_PAGE_SIZE,
    },
    dashboardSalesPageInfo: {
        list: [],
        total: 0,
        page: 1,
        size: SALES_PAGE_SIZE,
    },
    newSaleActiveClient: null,
    newSaleActiveAmount: null,
    newSaleActiveTag: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SALE_TAGS:
            return { ...state };
        case FETCH_SALE_TAGS_SUCCESS:
            return { ...state, saleTagsPageInfo: action.payload };
        case FETCH_SALE_TAGS_ERROR:
            return { ...state };
        case FETCH_SALES:
            return { ...state };
        case FETCH_SALES_SUCCESS:
            return { ...state, salesPageInfo: action.payload };
        case FETCH_SALES_ERROR:
            return { ...state };
        case FETCH_DASHBOARD_SALES_SUCCESS:
            return { ...state, dashboardSalesPageInfo: action.payload };
        case SET_SALE_ACTIVE_CLIENT:
            return { ...state, newSaleActiveClient: action.payload.data };
        case SET_SALE_ACTIVE_AMOUNT:
            return { ...state, newSaleActiveAmount: action.payload.data };
        case SET_SALE_ACTIVE_TAG:
            return { ...state, newSaleActiveTag: action.payload.data };
        case SET_SALE_PAGE_PAGINATION:
            return {
                ...state,
                salesPageInfo: {
                    ...state.salesPageInfo,
                    ...action.payload.data,
                    list: state.salesPageInfo.list,
                },
            };
        default:
            return { ...state };
    }
};
