import {
    CREATE_SALE,
    FETCH_SALES,
    FETCH_SALES_SUCCESS,
    FETCH_SALES_ERROR,
    FETCH_DASHBOARD_SALES,
    FETCH_DASHBOARD_SALES_SUCCESS,
    FETCH_DASHBOARD_SALES_ERROR,
    DELETE_SALE,
    UPDATE_SALE,
    SET_SALE_ACTIVE_CLIENT,
    SET_SALE_ACTIVE_AMOUNT,
    SET_SALE_ACTIVE_TAG,
    SET_SALE_PAGE_PAGINATION,
} from '../actions';

export const createSale = (data, history, from, { onSuccess = () => {} }) => ({
    type: CREATE_SALE,
    payload: { data, history, from, onSuccess },
});

export const loadSales = (params) => ({
    type: FETCH_SALES,
    payload: { page: params.page, customer: params.customer },
});

export const loadSalesSuccess = (list, page, total, size) => ({
    type: FETCH_SALES_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});

export const loadSalesError = (message) => ({
    type: FETCH_SALES_ERROR,
    payload: { message },
});

export const loadDashboardSales = (params) => ({
    type: FETCH_DASHBOARD_SALES,
    payload: params,
});

export const loadDashboardSalesSuccess = (list, page, total, size) => ({
    type: FETCH_DASHBOARD_SALES_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});

export const loadDashboardSalesError = (message) => ({
    type: FETCH_DASHBOARD_SALES_ERROR,
    payload: { message },
});

export const deleteSale = (params, page) => ({
    type: DELETE_SALE,
    payload: { params, page },
});

export const updateSale = (data, history, from, { onSuccess = () => {} }) => ({
    type: UPDATE_SALE,
    payload: { data, history, from, onSuccess },
});
export const setSaleActiveClient = (data) => ({
    type: SET_SALE_ACTIVE_CLIENT,
    payload: { data },
});
export const setSaleActiveAmount = (data) => ({
    type: SET_SALE_ACTIVE_AMOUNT,
    payload: { data },
});
export const setSaleActiveTag = (data) => ({
    type: SET_SALE_ACTIVE_TAG,
    payload: { data },
});

export const setSalePagePagination = (data) => ({
    type: SET_SALE_PAGE_PAGINATION,
    payload: { data },
});
