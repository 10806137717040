import {
    CREATE_CHECQUE,
    CREATE_CHECQUE_SUCCESS,
    CREATE_CHECQUE_ERROR,
    FETCH_CHECQUES,
    CHECQUE_PAGE_SIZE,
    PAGE_SIZE,
    FETCH_CHECQUES_SUCCESS,
    FETCH_CHECQUES_ERROR,

    FETCH_CHECQUES_ENVOYEE,
    FETCH_CHECQUES_ENVOYEE_SUCCESS,
    FETCH_CHECQUES_ENVOYEE_ERROR,

    DELETE_CHECQUE,

    UPDATE_CHECQUE, FETCH_CHECQUES_USED, FETCH_CHECQUES_USED_SUCCESS
} from '../actions';

const INIT_STATE = {
    checquesPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CHECQUE_PAGE_SIZE
    },
    checquesEnvoyeePageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CHECQUE_PAGE_SIZE
    },
    checquesUsedPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CHECQUE_PAGE_SIZE
    }
  };

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CHECQUES:
            return { ...state,  };
        case FETCH_CHECQUES_SUCCESS:
            return { ...state, checquesPageInfo: action.payload};
        case FETCH_CHECQUES_ERROR:
            return { ...state, };
        case FETCH_CHECQUES_ENVOYEE:
            return { ...state,  };
        case FETCH_CHECQUES_USED:
            return { ...state,  };
        case FETCH_CHECQUES_USED_SUCCESS:
            return { ...state, checquesUsedPageInfo: action.payload};
        case FETCH_CHECQUES_ENVOYEE_SUCCESS:
            return { ...state, checquesEnvoyeePageInfo: action.payload};
        case FETCH_CHECQUES_ENVOYEE_ERROR:
            return { ...state, };
        default: return { ...state };
    }
}
