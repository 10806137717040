import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    CREATE_CAMPAGNE,
    CREATE_CAMPAGNE_SUCCESS,
    CREATE_CAMPAGNE_ERROR,
    FETCH_CAMPAGNES,
    FETCH_CAMPAGNES_SUCCESS,
    FETCH_CAMPAGNES_ERROR,
    DELETE_CAMPAGNE,
    UPDATE_CAMPAGNE,
    CAMPAGNE_PAGE_SIZE,
    CAMPAGNE_TAGS_PAGE_SIZE,
    DUPLICATE_CAMPAGNE,
} from '../actions';

import {
    createCampagneSuccess,
    createCampagneError,
    loadCampagnesSuccess,
    loadCampagnesError,
    loadCampagnes,
    showLoader,
    hideLoader,
} from '../actions';

import {
    showError,
    showInfo,
    showSuccess,
    showWarning,
} from '../../services/utils';

import {
    getCampagnesRequest,
    createCampagneRequest,
    deleteCampagneRequest,
    updateCampagneRequest,
} from '../../services/api';

function* getCampagnesAsync({ payload }) {
    console.log(payload);
    payload.pageSize = CAMPAGNE_PAGE_SIZE;
    try {
        yield put(showLoader());
        const response = yield call(getCampagnesRequest, payload);
        console.log(response);

        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(
                loadCampagnesSuccess(
                    response['hydra:member'],
                    payload.page,
                    response['hydra:totalItems'],
                    CAMPAGNE_TAGS_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadCampagnesError(error['hydra:description']));
    }
}

function* createCampagnesAsync({ payload }) {
    console.log(payload);
    const { data, history, from } = payload;
    console.log(from);
    try {
        yield put(showLoader());
        const response = yield call(createCampagneRequest, data);
        console.log(response);

        if (response) {
            yield put(hideLoader());
            showSuccess('Félicitations!', 'Campagne créée!');
            history.push(from);
        }
    } catch (error) {
        yield put(hideLoader());
        //yield put(loadClientTagsError(error['hydra:description']));
        showError('Désolé!', error['hydra:description']);
    }
}
function* duplicateCampagnesAsync({ payload }) {
    const { data, page } = payload;
    try {
        yield put(showLoader());
        const response = yield call(createCampagneRequest, data);
        console.log(response, 'duplicateCampagnesAsync');
        if (response) {
            yield put(hideLoader());
            showSuccess('Félicitations!', 'Campagne dupliquée!');
            yield put(loadCampagnes({ page }));
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* deleteCampagnesAsync({ payload }) {
    console.log(payload);
    try {
        yield put(showLoader());
        const response = yield call(deleteCampagneRequest, payload.params.id);
        console.log(response);
        if (response) {
            yield put(hideLoader());
            yield put(loadCampagnes({ page: payload.page }));
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* updateCampagnesAsync({ payload }) {
    // console.log(payload)
    const { history, data, from } = payload;

    console.log(data);
    try {
        yield put(showLoader());
        const response = yield call(updateCampagneRequest, data);
        console.log(response);

        if (response) {
            yield put(hideLoader());
            showSuccess('Félicitations!', 'Mise à jour de la campagne!');
            history.push(from);
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

export function* watchLoadCampagne() {
    yield takeEvery(FETCH_CAMPAGNES, getCampagnesAsync);
}
export function* watchcreateCampagne() {
    yield takeEvery(CREATE_CAMPAGNE, createCampagnesAsync);
}
export function* watchdeleteCampagne() {
    yield takeEvery(DELETE_CAMPAGNE, deleteCampagnesAsync);
}
export function* watchupdateCampagne() {
    yield takeEvery(UPDATE_CAMPAGNE, updateCampagnesAsync);
}
export function* watchduplicateCampagne() {
    yield takeEvery(DUPLICATE_CAMPAGNE, duplicateCampagnesAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadCampagne),
        fork(watchcreateCampagne),
        fork(watchdeleteCampagne),
        fork(watchupdateCampagne),
        fork(watchduplicateCampagne),
    ]);
}
