
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    FETCH_CLIENT_TAGS,
    UPDATE_CLIENT_TAG,
    PAGE_SIZE,
    DELETE_CLIENT_TAG,
    CLIENT_TAGS_PAGE_SIZE,
    CREATE_CLIENT_TAG,
    CREATE_CLIENT,
    FETCH_CLIENTS,
    CLIENTS_PAGE_SIZE,
    DELETE_CLIENT,
    UPDATE_CLIENT,
    setSaleActiveClient
} from '../actions';

import {
    loadClientTagsSuccess,
    loadClientTagsError,
    loadClientTags,
    loadSaleTags,
    createClientSuccess,
    createClientError,
    loadClientsSuccess,
    loadClientsError,
    loadClients,
    showLoader,
    hideLoader
} from '../actions';

import {
    showError,
    showInfo,
    showSuccess,
    showWarning
} from '../../services/utils';

import {
    getClientTagsRequest,
    updateTagRequest,
    deleteTagRequest,
    addClientTagRequest,
    createClientRequest,
    getClientsRequest,
    deleteClientRequest,
    updateClientRequest
} from '../../services/api';

function* getClientTagsAsync({ payload }) {
    console.log(payload)
    payload.pageSize = CLIENT_TAGS_PAGE_SIZE;
    try {
        yield put(showLoader());
        const response = yield call(getClientTagsRequest, payload);
        console.log(response)
        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(loadClientTagsSuccess(response['hydra:member'], payload.page, response['hydra:totalItems'], CLIENT_TAGS_PAGE_SIZE));
        }

    } catch (error) {
        yield put(hideLoader());
        yield put(loadClientTagsError(error['hydra:description']));
        showError('Désolé!', error['hydra:description']);
    }
}

function* getClientsAsync({ payload }) {
    payload.pageSize = CLIENTS_PAGE_SIZE;
    console.log('getClientsAsync payload:', payload)
    try {
        yield put(showLoader());
        const response = yield call(getClientsRequest, payload);
        console.log('getClientsAsync response', response)
        // return false;
        yield put(hideLoader());
        if (response && response.items) {
            const reformatedData = response.items.map(item => {
                if(item.dateOfBirth){
                    item.dateOfBirth= item.dateOfBirth.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1") +'T00:00:00+00:00'
                }
                if(item.dateOfLastPurchase){
                    item.dateOfLastPurchase= item.dateOfLastPurchase.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1") +'T00:00:00+00:00'
                }
                let tags = []
                if (item.tags) {
                    item.tags.forEach(tag => {
                        tags.push(`/tags/${tag.id}`)
                    });
                }
                item.tags = tags
                return item;
            });
            yield put(hideLoader());
            yield put(loadClientsSuccess(reformatedData, payload.page, response.count, CLIENT_TAGS_PAGE_SIZE));
        }

    } catch (error) {
        console.log('getClientsAsync error', error)
        yield put(hideLoader());
        yield put(loadClientsError(error['hydra:description']));
    }
}

function* createClientAsync({ payload }) {
    console.log(payload)
    const { history, data, from } = payload;

    console.log(data)
    try {
        yield put(showLoader());
        const response = yield call(createClientRequest, data);
        console.log(response)

        if (response) {
            yield put(hideLoader());
            yield put(createClientSuccess(response));
            showSuccess('Félicitations!', 'Le client a été créé avec succès!');
            history.push(from);
        }

    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
        yield put(createClientError(error['hydra:description']));

    }
}

function* updateClientTagAsync({ payload }) {
    console.log('updateClientTagAsync payload', payload)
    try {
        yield put(showLoader());
        const response = yield call(updateTagRequest, payload.params);
        console.log('updateClientTagAsync response', response)
        if (response) {
            yield put(hideLoader());
            if (payload.type == 1) {
                yield put(loadClientTags({ page: payload.page }));
            }
            if (payload.type == 2) {
                yield put(loadSaleTags({ page: payload.page }));
            }
        }

    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* updateClientAsync({ payload }) {
    console.log('updateClientAsync payload', payload)
    const { history, data, from, gauge, showPopup=true } = payload;
    try {
        yield put(showLoader());
        const response = yield call(updateClientRequest, data);
        console.log('updateClientAsync response', response)
        if (response) {
            response.gauge = gauge
            yield put(hideLoader());
            // yield put(createClientSuccess(response));
            if(showPopup)
            showSuccess('Félicitations!', 'Le client a été mis à jour avec succès!');
            yield put(setSaleActiveClient(response));
            if(showPopup)
            history.push(from);
        }

    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
        // yield put(createClientError(error['hydra:description']));

    }
}

function* deleteClientTagAsync({ payload }) {
    console.log(payload)
    console.log(payload.type)
    try {
        yield put(showLoader());
        const response = yield call(deleteTagRequest, payload.params.id);
        console.log(response)
        if (response) {
            yield put(hideLoader());
            if (payload.type == 1) {
                yield put(loadClientTags({ page: payload.page }));
            }
            if (payload.type == 2) {
                yield put(loadSaleTags({ page: payload.page }));
            }

        }

    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* addClientTagAsync({ payload }) {
    console.log(payload)
    try {
        yield put(showLoader());
        const response = yield call(addClientTagRequest, payload.params);
        console.log(response)

        if (response.type == 1) {
            yield put(hideLoader());
            yield put(loadClientTags({ page: payload.page }));
        }
        if (response.type == 2) {
            yield put(hideLoader());
            yield put(loadSaleTags({ page: payload.page }));
        }

    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* deleteClientAsync({ payload }) {
    console.log(payload)
    try {
        yield put(showLoader());
        const response = yield call(deleteClientRequest, payload.params.id);
        console.log(response)

        if (response) {
            yield put(showLoader());
            yield put(loadClients({ page: payload.page }));
        }

    } catch (error) {
        yield put(showLoader());
        // showError('Désolé!', error['hydra:description']);
        showError('Désolé!', 'Le client ne peut pas être supprimé car il est lié à des ventes');
    }
}


export function* watchLoadClientTags() {
    yield takeEvery(FETCH_CLIENT_TAGS, getClientTagsAsync);
}

export function* watchLoadClients() {
    yield takeEvery(FETCH_CLIENTS, getClientsAsync);
}

export function* watchUpdateClientTag() {
    yield takeEvery(UPDATE_CLIENT_TAG, updateClientTagAsync);
}

export function* watchUpdateClient() {
    yield takeEvery(UPDATE_CLIENT, updateClientAsync);
}

export function* watchDeleteClientTag() {
    yield takeEvery(DELETE_CLIENT_TAG, deleteClientTagAsync);
}

export function* watchAddClientTag() {
    yield takeEvery(CREATE_CLIENT_TAG, addClientTagAsync);
}

export function* watchCreateClient() {
    yield takeEvery(CREATE_CLIENT, createClientAsync);
}

export function* watchDeleteClient() {
    yield takeEvery(DELETE_CLIENT, deleteClientAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchUpdateClientTag),
        fork(watchLoadClientTags),
        fork(watchDeleteClientTag),
        fork(watchAddClientTag),
        fork(watchCreateClient),
        fork(watchLoadClients),
        fork(watchDeleteClient),
        fork(watchUpdateClient)
    ]);
}