import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR
} from '../actions';

const INIT_STATE = {
    // user: localStorage.getItem('user_id'),
    loading: false,
    error: '',
    token: sessionStorage.getItem('icust_token') || '',
    refreshToken: sessionStorage.getItem('icust_refresh_token') || '',
    rememberMe: sessionStorage.getItem('icust_remember_me'),
    logined: (sessionStorage.getItem('icust_token') != 'null') && (sessionStorage.getItem('icust_remember_me') == 'true') || false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, error: '' };
        case LOGIN_USER_SUCCESS:
            return { ...state, logined: true, token: action.payload.token, refreshToken: action.payload.refresh_token, error: '' };
        case LOGIN_USER_ERROR:
            return { ...state, logined: false, token: '', refreshToken: '',  error: action.payload.message };
        case REFRESH_TOKEN_SUCCESS:
            return { ...state, logined: true, token: action.payload.token, refreshToken: action.payload.refresh_token, error: '' };
        case REFRESH_TOKEN_ERROR:
            return { ...state, logined: false, token: '', refreshToken: '',  error: action.payload.message };
        case LOGOUT_USER_SUCCESS:
            return { ...state, logined: false, token: '', refreshToken: '', error: '' };
        default: return { ...state };
    }
}