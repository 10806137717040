/* GLOBALS */
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

/* USER */
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';

/* NEWS */
export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR';

/* CLIENT */
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR';

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CLIENTS_PAGE_SIZE = 5;

export const DELETE_CLIENT = 'DELETE_CLIENT';

/* CLIENT TAGS*/
export const CREATE_CLIENT_TAG = 'CREATE_CLIENT_TAG';
export const CREATE_CLIENT_TAG_SUCCESS = 'CREATE_CLIENT_TAG_SUCCESS';
export const CREATE_CLIENT_TAG_ERROR = 'CREATE_CLIENT_TAG_ERROR';

export const FETCH_CLIENT_TAGS = 'FETCH_CLIENT_TAGS';
export const FETCH_CLIENT_TAGS_SUCCESS = 'FETCH_CLIENT_TAGS_SUCCESS';
export const FETCH_CLIENT_TAGS_ERROR = 'FETCH_CLIENT_TAGS_ERROR';
export const UPDATE_CLIENT_TAG = 'UPDATE_CLIENT_TAG';
export const UPDATE_CLIENT_TAG_SUCCESS = 'UPDATE_CLIENT_TAG_SUCCESS';
export const UPDATE_CLIENT_TAG_ERROR = 'UPDATE_CLIENT_TAG_ERROR';

export const DELETE_CLIENT_TAG = 'DELETE_CLIENT_TAG';

export const CLIENT_TAGS_PAGE_SIZE = 5;

/* SALES */
export const CREATE_SALE = 'CREATE_SALE';
export const CREATE_SALE_SUCCESS = 'CREATE_SALE_SUCCESS';
export const CREATE_SALE_ERROR = 'CREATE_SALE_ERROR';

export const FETCH_SALES = 'FETCH_SALES';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'FETCH_SALES_ERROR';

export const FETCH_DASHBOARD_SALES = 'FETCH_DASHBOARD_SALES';
export const FETCH_DASHBOARD_SALES_SUCCESS = 'FETCH_DASHBOARD_SALES_SUCCESS';
export const FETCH_DASHBOARD_SALES_ERROR = 'FETCH_SALES_ERROR';

export const UPDATE_SALE = 'UPDATE_SALE';
export const SET_SALE_ACTIVE_CLIENT = 'SET_SALE_ACTIVE_CLIENT';
export const SET_SALE_ACTIVE_AMOUNT = 'SET_SALE_ACTIVE_AMOUNT';
export const SET_SALE_ACTIVE_TAG = 'SET_SALE_ACTIVE_TAG';
export const SET_SALE_PAGE_PAGINATION = `SET_SALE_PAGE_PAGINATION`;

export const SALES_PAGE_SIZE = 5;

export const DELETE_SALE = 'DELETE_SALE';

/* SALE TAGS*/
export const CREATE_SALE_TAG = 'CREATE_SALE_TAG';
export const CREATE_SALE_TAG_SUCCESS = 'CREATE_SALE_TAG_SUCCESS';
export const CREATE_SALE_TAG_ERROR = 'CREATE_SALE_TAG_ERROR';

export const FETCH_SALE_TAGS = 'FETCH_SALE_TAGS';
export const FETCH_SALE_TAGS_SUCCESS = 'FETCH_SALE_TAGS_SUCCESS';
export const FETCH_SALE_TAGS_ERROR = 'FETCH_SALE_TAGS_ERROR';
export const UPDATE_SALE_TAG = 'UPDATE_SALE_TAG';
export const UPDATE_SALE_TAG_SUCCESS = 'UPDATE_SALE_TAG_SUCCESS';
export const UPDATE_SALE_TAG_ERROR = 'UPDATE_SALE_TAG_ERROR';

export const DELETE_SALE_TAG = 'DELETE_SALE_TAG';

export const SALE_TAGS_PAGE_SIZE = 5;

/* SEGMENT */
export const CREATE_SEGMENT = 'CREATE_SEGMENT';
export const CREATE_SEGMENT_SUCCESS = 'CREATE_SEGMENT_SUCCESS';
export const CREATE_SEGMENT_ERROR = 'CREATE_SEGMENT_ERROR';

export const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
export const FETCH_SEGMENTS_SUCCESS = 'FETCH_SEGMENTS_SUCCESS';
export const FETCH_SEGMENTS_ERROR = 'FETCH_CLIENTS_ERROR';

export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';
export const SEGMENT_PAGE_SIZE = 5;

export const DELETE_SEGMENT = 'DELETE_SEGMENT';

/* SEGMENTS TAGS*/
export const CREATE_SEGMENT_TAG = 'CREATE_SEGMENT_TAG';
export const CREATE_SEGMENT_TAG_SUCCESS = 'CREATE_SEGMENT_TAG_SUCCESS';
export const CREATE_SEGMENT_TAG_ERROR = 'CREATE_SEGMENT_TAG_ERROR';

export const FETCH_SEGMENT_TAGS = 'FETCH_SEGMENT_TAGS';
export const FETCH_SEGMENT_TAGS_SUCCESS = 'FETCH_SEGMENT_TAGS_SUCCESS';
export const FETCH_SEGMENT_TAGS_ERROR = 'FETCH_SEGMENT_TAGS_ERROR';
export const UPDATE_SEGMENT_TAG = 'UPDATE_SEGMENT_TAG';
export const UPDATE_SEGMENT_TAG_SUCCESS = 'UPDATE_SEGMENT_TAG_SUCCESS';
export const UPDATE_SEGMENT_TAG_ERROR = 'UPDATE_SEGMENT_TAG_ERROR';

export const DELETE_SEGMENT_TAG = 'DELETE_SEGMENT_TAG';

export const SEGMENT_TAGS_PAGE_SIZE = 5;

/*actions segments*/
export const FETCH_ACTIONS_SEGMENTS = 'FETCH_ACTIONS_SEGMENTS';
export const FETCH_ACTIONS_SEGMENTS_SUCCESS = 'FETCH_ACTIONS_SEGMENTS_SUCCESS';
export const FETCH_ACTIONS_SEGMENTS_ERROR = 'FETCH_ACTIONS_SEGMENTS_ERROR';

/* CAMPAGNES */
export const CREATE_CAMPAGNE = 'CREATE_CAMPAGNE';
export const CREATE_CAMPAGNE_SUCCESS = 'CREATE_CAMPAGNE_SUCCESS';
export const CREATE_CAMPAGNE_ERROR = 'CREATE_CAMPAGNE_ERROR';

export const FETCH_CAMPAGNES = 'FETCH_CAMPAGNES';
export const FETCH_CAMPAGNES_SUCCESS = 'FETCH_CAMPAGNES_SUCCESS';
export const FETCH_CAMPAGNES_ERROR = 'FETCHCAMPAGNES_ERROR';

export const SAVE_SEGMENT_FOR_SMS = 'SAVE_SEGMENT_FOR_SMS';
export const UPDATE_SEGMENT_FOR_SMS = 'UPDATE_SEGMENT_FOR_SMS';
export const SAVE_SEGMENT_PROPS_FOR_SMS = 'SAVE_SEGMENT_PROPS_FOR_SMS';

export const SMS_CAMPAGNE_FORM_DATA = 'SMS_CAMPAGNE_FORM_DATA';

export const UPDATE_CAMPAGNE = 'UPDATE_CAMPAGNE';
export const CAMPAGNE_PAGE_SIZE = 5;

export const DELETE_CAMPAGNE = 'DELETE_CAMPAGNE';

export const DUPLICATE_CAMPAGNE = 'DUPLICATE_CAMPAGNE';

/* CAMPAGNES TAGS*/
export const CREATE_CAMPAGNE_TAG = 'CREATE_CAMPAGNE_TAG';
export const CREATE_CAMPAGNE_TAG_SUCCESS = 'CREATE_CAMPAGNE_TAG_SUCCESS';
export const CREATE_CAMPAGNE_TAG_ERROR = 'CREATE_SEGMENT_TAG_ERROR';

export const FETCH_CAMPAGNE_TAGS = 'FETCH_CAMPAGNE_TAGS';
export const FETCH_CAMPAGNE_TAGS_SUCCESS = 'FETCH_CAMPAGNE_TAGS_SUCCESS';
export const FETCH_CAMPAGNE_TAGS_ERROR = 'FETCH_CAMPAGNE_TAGS_ERROR';
export const UPDATE_CAMPAGNE_TAG = 'UPDATE_CAMPAGNE_TAG';
export const UPDATE_CAMPAGNE_TAG_SUCCESS = 'UPDATE_CAMPAGNE_TAG_SUCCESS';
export const UPDATE_CAMPAGNE_TAG_ERROR = 'UPDATE_CAMPAGNE_TAG_ERROR';

export const DELETE_CAMPAGNE_TAG = 'DELETE_CAMPAGNE_TAG';

export const CAMPAGNE_TAGS_PAGE_SIZE = 5;

/* CHECQUES */
export const CREATE_CHECQUE = 'CREATE_CHECQUE';
export const CREATE_CHECQUE_SUCCESS = 'CREATE_CHECQUE_SUCCESS';
export const CREATE_CHECQUE_ERROR = 'CREATE_CHECQUE_ERROR';

export const FETCH_CHECQUES = 'FETCH_CHECQUES';
export const FETCH_CHECQUES_SUCCESS = 'FETCH_CHECQUES_SUCCESS';
export const FETCH_CHECQUES_ERROR = 'FETCHCHECQUES_ERROR';

export const FETCH_CHECQUES_ENVOYEE = 'FETCH_CHECQUES_ENVOYEE';
export const FETCH_CHECQUES_USED = 'FETCH_CHECQUES_USED';
export const FETCH_CHECQUES_USED_SUCCESS = 'FETCH_CHECQUES_USED_SUCCESS';
export const FETCH_CHECQUES_ENVOYEE_SUCCESS = 'FETCH_CHECQUES_ENVOYEE_SUCCESS';
export const FETCH_CHECQUES_ENVOYEE_ERROR = 'FETCH_CHECQUES_ENVOYEE_ERROR';

export const UPDATE_CHECQUE = 'UPDATE_CHECQUE';
export const UPDATE_CHECQUES = 'UPDATE_CHECQUES';
export const CHECQUE_PAGE_SIZE = 5;

export const DELETE_CHECQUE = 'DELETE_CHECQUE';

export const PAGE_SIZE = 10;

/*  SMS VOCAL CAMPAGNES ACTIONS  */

export const SAVE_SEGMENT = 'SAVE_SEGMENT';
export const SAVE_HEADER_DATA = 'SAVE_HEADER_DATA';
export const SAVE_VOCAL_NEW_MESSAGE_DATA = 'SAVE_VOCAL_NEW_MESSAGE_DATA';
export const SAVE_VOCAL_IMPORT_MESSAGE = 'SAVE_VOCAL_IMPORT_MESSAGE';
export const GET_VOICE_MESSAGE = 'GET_VOICE_MESSAGE';
export const GET_VOICE_MESSAGE_SUCCESS = 'GET_VOICE_MESSAGE_SUCCESS';
export const GET_VOICE_MESSAGE_ERROR = 'GET_VOICE_MESSAGE_ERROR';
export const VOICE_MESSAGE_PAGE_SIZE = 5;
export const GET_AUDIO_MESSAGE = 'GET_AUDIO_MESSAGE';
export const GET_AUDIO_MESSAGE_SUCCESS = 'GET_AUDIO_MESSAGE_SUCCESS';
export const GET_AUDIO_MESSAGE_ERROR = 'GET_AUDIO_MESSAGE_ERROR';

export const DELETE_VOICE_MESSAGE = 'DELETE_VOICE_MESSAGE';
export const DELETE_VOICE_MESSAGE_SUCCESS = 'DELETE_VOICE_MESSAGE_SUCCESS';
export const DELETE_VOICE_MESSAGE_ERROR = 'DELETE_VOICE_MESSAGE_ERROR';

export const UPDATE_MESSAGE_TITLE = 'UPDATE_MESSAGE_TITLE';
export const UPDATE_MESSAGE_TITLE_SUCCESS = 'UPDATE_MESSAGE_TITLE_SUCCESS';
export const UPDATE_MESSAGE_TITLE_ERROR = 'UPDATE_MESSAGE_TITLE_ERROR';

export const GET_MESSAGE_CODE = 'GET_MESSAGE_CODE';
export const GET_MESSAGE_CODE_SUCCESS = 'GET_MESSAGE_CODE_SUCCESS';
export const GET_MESSAGE_CODE_ERROR = 'GET_MESSAGE_CODE_ERROR';

export const GET_BUDGET = 'GET_BUDGET';
export const GET_BUDGET_SUCCESS = 'GET_BUDGET_SUCCESS';
export const GET_BUDGET_ERROR = 'GET_BUDGET_ERROR';

export * from './auth/actions';
export * from './user/actions';

export * from './auth/actions';
export * from './user/actions';

export * from './globals/actions';
export * from './auth/actions';
export * from './user/actions';
export * from './news/actions';
export * from './client/actions';
export * from './checques/actions';
export * from './sales/actions';
export * from './segment/actions';
export * from './campagnes/actions';
export * from './segmentActions/actions';
export * from './smsVocalCampagnes/actions';
