
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  FETCH_ACTIONS_SEGMENTS,
  FETCH_ACTIONS_SEGMENTS_SUCCESS,
  FETCH_ACTIONS_SEGMENTS_ERROR,
} from '../actions';

import {
  loadActionsSegmentsSuccess,
  loadActionsSegmentsError,
  showLoader,
  hideLoader
} from '../actions';


import { 
  showError,
  showInfo,
  showSuccess,
  showWarning 
} from '../../services/utils';

import {
  getActionsSegmentRequest,
} from '../../services/api';


function* getActionsSegmentAsync() {
  try {
    yield put(showLoader());
    const response = yield call(getActionsSegmentRequest);
    if(response && response['hydra:member']) {
      yield put(hideLoader());
      yield put(loadActionsSegmentsSuccess(response['hydra:member'] ));
    }
  } 
  catch (error) {
    yield put(hideLoader());
    yield put(loadActionsSegmentsError(error['hydra:description']));
  }
}


export function* watchfetchActionsSegment() {
    yield takeEvery(FETCH_ACTIONS_SEGMENTS, getActionsSegmentAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchfetchActionsSegment)
    ]);
}