import {
  SHOW_LOADER,
  HIDE_LOADER,
} from '../actions';

export const showLoader = () => ({
  type: SHOW_LOADER,
  payload: {}
});
export const hideLoader = () => ({
  type: HIDE_LOADER,
  payload: {}
});
