import {
  FETCH_ACTIONS_SEGMENTS,
  FETCH_ACTIONS_SEGMENTS_SUCCESS,
  FETCH_ACTIONS_SEGMENTS_ERROR,
} from '../actions';

export const loadActionsSegments = () => ({
  type: FETCH_ACTIONS_SEGMENTS,
  payload: { }
});
export const loadActionsSegmentsSuccess = (list) => ({
  type: FETCH_ACTIONS_SEGMENTS_SUCCESS,
  payload: list
});
export const loadActionsSegmentsError = (message) => ({
  type: FETCH_ACTIONS_SEGMENTS_ERROR,
  payload: { message }
});