import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  RECOVER_PASSWORD,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  
} from '../actions';

export const createUser = (data, history) => ({
  type: CREATE_USER,
  payload: { data, history }
});
export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user
});
export const createUserError = (message) => ({
  type: CREATE_USER_ERROR,
  payload: { message }
});

export const recoverPassword = (data, history) => ({
  type: RECOVER_PASSWORD,
  payload: { data, history }
});

export const loadUser = () => ({
  type: LOAD_USER,
  payload: { }
});
export const loadUserSuccess = (user) => ({
  type: LOAD_USER_SUCCESS,
  payload: user
});
export const loadUserError = (message) => ({
  type: LOAD_USER_ERROR,
  payload: { message }
});