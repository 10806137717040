import {
  CREATE_SEGMENT,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_ERROR,
  FETCH_SEGMENTS,
  PAGE_SIZE,
  SEGMENT_PAGE_SIZE,
  SEGMENT_TAGS_PAGE_SIZE,
  FETCH_SEGMENTS_SUCCESS,
  FETCH_SEGMENTS_ERROR,
  DELETE_SEGMENT,
  UPDATE_SEGMENT
} from '../actions';

const INIT_STATE = {
    // clientTags: [],
    segmentTagsPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: SEGMENT_TAGS_PAGE_SIZE
    },
    segmentsPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: SEGMENT_PAGE_SIZE
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SEGMENTS:
            return { ...state,  };
        case FETCH_SEGMENTS_SUCCESS:
            return { ...state, segmentsPageInfo: action.payload};
        case FETCH_SEGMENTS_ERROR:
            return { ...state, };
        default: return { ...state };
    }
}