import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    CREATE_SEGMENT,
    CREATE_SEGMENT_SUCCESS,
    CREATE_SEGMENT_ERROR,
    FETCH_SEGMENTS,
    FETCH_SEGMENTS_SUCCESS,
    FETCH_SEGMENTS_ERROR,
    DELETE_SEGMENT,
    UPDATE_SEGMENT,
    SEGMENT_PAGE_SIZE,
    SEGMENT_TAGS_PAGE_SIZE,
    save_segment_for_sms,
} from '../actions';

import {
    createSegment,
    createSegmentSuccess,
    createSegmentError,
    updateSegment,
    loadSegmentsSuccess,
    loadSegmentsError,
    loadSegments,
    showLoader,
    hideLoader,
} from '../actions';

import {
    showError,
    showInfo,
    showSuccess,
    showWarning,
} from '../../services/utils';

import {
    createSegmentRequest,
    getSegmentRequest,
    deleteSegmentRequest,
    updateSegmentRequest,
} from '../../services/api';

function* createSegmentAsync({ payload }) {
    const { data, history, from } = payload;
    console.log('createSegmentAsync payload', payload)
    try {
        yield put(showLoader());
        const response = yield call(createSegmentRequest, data);

        if (response) {
            yield put(hideLoader());
            history.push(from);
        }
    } catch (error) {
        yield put(hideLoader());
        //yield put(loadClientTagsError(error['hydra:description']));
        showError('Désolé!', error['hydra:description']);
    }
}

function* getSegmentAsync({ payload }) {
    payload.pageSize = SEGMENT_PAGE_SIZE;
    try {
        yield put(showLoader());
        const response = yield call(getSegmentRequest, payload);
        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(
                loadSegmentsSuccess(
                    response['hydra:member'],
                    payload.page,
                    response['hydra:totalItems'],
                    SEGMENT_TAGS_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadSegmentsError(error['hydra:description']));
    }
}

function* deleteSegmentAsync({ payload }) {
    try {
        yield put(showLoader());
        const response = yield call(deleteSegmentRequest, payload.params.id);
        if (response) {
            yield put(hideLoader());
            yield put(loadSegments({ page: payload.page }));
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* updateSegmentAsync({ payload }) {
    const { history, data, from } = payload;
    console.log('updateSegmentAsync ',{ history, data, from });
    try {
        yield put(showLoader());
        const response = yield call(updateSegmentRequest, data);
        if (response) {
            yield put(hideLoader());
            yield put(save_segment_for_sms(response));
            if (from === '/smscampagnes') {
                history.push({
                    pathname: from,
                    smsCampagneFormProps: 'smsCampagneFormProps',
                });
            } else {
                console.log('pushing the history', from);
                history.push(from);
            }
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

export function* watchCreateSegment() {
    yield takeEvery(CREATE_SEGMENT, createSegmentAsync);
}
export function* watchfetchSegment() {
    yield takeEvery(FETCH_SEGMENTS, getSegmentAsync);
}
export function* watchdeleteSegment() {
    yield takeEvery(DELETE_SEGMENT, deleteSegmentAsync);
}
export function* watchupdateSegment() {
    yield takeEvery(UPDATE_SEGMENT, updateSegmentAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchCreateSegment),
        fork(watchfetchSegment),
        fork(watchdeleteSegment),
        fork(watchupdateSegment),
    ]);
}
