// @format
import { handleError, verifyResponse } from './utils';
import { CAMPAIGN_SENDING_MODES } from '../redux/actions';

import config from '../config';

const BASE_URL = config.BASE_URL;

const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization:
        sessionStorage.getItem('icust_token') != 'null'
            ? 'Bearer ' + sessionStorage.getItem('icust_token')
            : '',
});

const patchHeaders = new Headers({
    'Content-Type': 'application/merge-patch+json',
    Authorization:
        sessionStorage.getItem('icust_token') != 'null'
            ? 'Bearer ' + sessionStorage.getItem('icust_token')
            : '',
});

export function getFormData(d) {
    const data = new FormData();
    const keys = Object.keys(d);
    keys.forEach((k) => data.append(k, d[k]));
    return data;
}

export const initToken = (token) => {
    headers.set('Authorization', `Bearer ${token}`);
    patchHeaders.set('Authorization', `Bearer ${token}`);
};

export const POST = (endpoint, body) =>
    fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: body ? JSON.stringify(body) : undefined,
    }).then(verifyResponse);

export const PUT = (endpoint, body) =>
    fetch(`${BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: headers,
        body: body ? JSON.stringify(body) : undefined,
    }).then(verifyResponse);

export const GET = (endpoint) =>
    fetch(`${BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: headers,
    }).then(verifyResponse);

export const DELETE = (endpoint) =>
    fetch(`${BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: headers,
    }).then(verifyResponse);

export const PATCH = (endpoint, body) =>
    fetch(`${BASE_URL}${endpoint}`, {
        method: 'PATCH',
        headers: patchHeaders,
        body: body ? JSON.stringify(body) : undefined,
    }).then(verifyResponse);

// *********************************
// User endpoints
// *********************************

// Login User
export const loginUserRequest = (body) =>
    POST('/login_check', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

export const getUserProfileRequest = () =>
    GET(`/users/profile`)
        .then((response) => response.json())
        .catch(handleError);

// Login User
export const refreshTokenRequest = (body) =>
    POST('/token/refresh', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// Create User
export const createUserRequest = (body) =>
    POST('/users', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// Recover User
export const passwordRecoveryRequest = (body) =>
    POST('/users/request-password-reset', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get news
export const getNewsRequest = () =>
    GET(`/home_news`)
        .then((response) => response.json())
        .catch(handleError);

// get home news
export const getHomeNewsRequest = () =>
    GET(`/home_news?isActive=true&order%5Bid%5D=desc`)
        .then((response) => response.json())
        .catch(handleError);

// get tags
export const getClientTagsRequest = (params) =>
    GET(
        `/tags/client?page=${params.page}&type=${params.type}&itemsPerPage=${params.pageSize}`
    )
        .then((response) => response.json())
        .catch(handleError);

// update tags
export const updateTagRequest = (body) =>
    PUT(`/tags/${body.id}`, {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// add tag
export const addClientTagRequest = (body) =>
    POST('/tags/client', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// Delete tags
export const deleteTagRequest = (id) =>
    DELETE(`/tags/${id}`)
        .then((response) => response)
        .catch(handleError);

// Create Clients
export const createClientRequest = (body) =>
    POST('/client/customers', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get Clients
export const getClientsRequest = (params) =>{
    console.log('getClientsRequest endpoint', `/client/customers?${params.searchTerm ? '&name=' + params.searchTerm : ''}&page=${params.page}&itemsPerPage=${params.pageSize}`)
    return GET(`/client/customers?${params.searchTerm ? '&name=' + params.searchTerm : ''}&page=${params.page}&itemsPerPage=${params.pageSize}`)
        // GET(`/client/customers?name=CORINNE&page=1`)
        .then((response) => response.json())
        .catch(handleError);
    }

// Delete client
export const deleteClientRequest = (id) =>
    DELETE(`/client/customers/${id}`)
        .then((response) => response)
        .catch(handleError);

// update client
export const updateClientRequest = (body) =>
    PUT(`/client/customers/${body.id}`, {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// Create Sale
export const createSaleRequest = (body) =>
    POST('/client/sales', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// update sale
export const updateSaleRequest = (body) =>
    PUT(`/client/sales/${body.id}`, {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get Sales
export const getSalesRequest = (params) =>
    GET(
        `/client/sales?page=${params.page}&itemsPerPage=${params.pageSize}${params.customer}`
    )
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);

// get dashboard Sales
export const getDashboardSalesRequest = (params) => {
    console.log(
        `getDashboardSalesRequest req ::`,
        `/client/sales?page=${params.page}&itemsPerPage=${params.pageSize}${params.urlParams}`
    );
    return (
        GET(
            `/client/sales?page=${params.page}&itemsPerPage=${params.pageSize}${params.urlParams}`
        )
            // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
            .then((response) => response.json())
            .catch(handleError)
    );
};

// Delete Sale
export const deleteSaleRequest = (id) =>
    DELETE(`/client/sales/${id}`)
        .then((response) => response)
        .catch(handleError);

// Create Segment
export const createSegmentRequest = (body) =>
    POST('/client/segments', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get Segment
export const getSegmentRequest = (params) =>
    GET(`/client/segments?page=${params.page}&itemsPerPage=${params.pageSize}`)
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);

// Delete segment
export const deleteSegmentRequest = (id) =>
    DELETE(`/segments/${id}`)
        .then((response) => response)
        .catch(handleError);

//update segment
export const updateSegmentRequest = (body) =>
    PUT(`/segments/${body.id}`, {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get campagnes
export const getCampagnesRequest = (params) =>
    GET(`/client/campaigns?page=${params.page}&itemsPerPage=${params.pageSize}`)
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);

// Create campagne
export const createCampagneRequest = (body) => {
    if (
        body &&
        (body.type === 'print' || body.sendingMode) &&
        (body.type === 'print' ||
            CAMPAIGN_SENDING_MODES.indexOf(body.sendingMode) !== -1)
    ) {
        return POST('/client/campaigns', {
            ...body,
        })
            .then((response) => response.json())
            .catch(handleError);
    } else {
        console.log('Campaign sending mode is not valid');
        return;
    }
};

// Delete campagne
export const deleteCampagneRequest = (id) =>
    DELETE(`/client/campaigns/${id}`)
        .then((response) => response)
        .catch(handleError);

//update campagne
export const updateCampagneRequest = (body) =>
    PUT(`/client/campaigns/${body.id}`, {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

// get Actions Segments
export const getActionsSegmentRequest = (params) =>
    GET('/client/segments?pagination=false')
        .then((response) => response.json())
        .catch(handleError);

// get Cheques
export const getChecquesRequest = (params) =>
    GET(`/client/coupons`)
        .then((response) => response.json())
        .catch(handleError);

export const getChecquesEnvoyeeRequest = (params) =>{
    // GET(`/client/coupons-by-status?status=${params.status} + params.startDate ? &dateFrom=${params.startDate}&dateTo=${params.endDate} : &period=${params.period}`)
    console.log('getChecquesEnvoyeeRequest', `/client/coupons-by-status?status=${params.status} + params.startDate ? &dateFrom=${params.startDate}&dateTo=${params.endDate} : &period=${params.period}`)
    return GET(`/client/coupons-by-status?status=${params.status}${params.urlParams}`)
        .then((response) => response.json())
        .catch(handleError);
}
export const getChecquesUsedRequest = (params) =>
    // GET(`/client/coupons-by-status?status=${params.status} + params.startDate ? &dateFrom=${params.startDate}&dateTo=${params.endDate} : &period=${params.period}`)
    GET(`/client/coupons-by-status?status=${params.status}${params.urlParams}`)
        .then((response) => response.json())
        .catch(handleError);

export const updateChecqueStatusRequest = (params) =>
    PUT(`/client/coupons/${params.id}`, {
        ...params,
    })
        .then((response) => response.json())
        .catch(handleError);

//get voice message

export const getVoiceMessage = (params) =>
    GET(
        `/voice-message/list?page=${params.page}&itemsPerPage=${params.pageSize}&pagination=true`
    )
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);

export const audioMessageRequst = (params) =>
    GET(`/voice-message/download/${params.data}`)
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);

export const deleteVoicMessageRequst = (id) =>
    DELETE(`/voice_messages/${id}`)
        .then((response) => response)
        .catch(handleError);

export const updateMessageRequst = (params) =>
    PATCH(`/voice_messages/${params.id}`, {
        ...params,
    })
        .then((response) => response.json())
        .catch(handleError);

export const getMessageCode = (body) =>
    POST('/voice-message/code', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

export const sendTestMessage = (body) =>
    POST('/client/campaigns/test/sms', {
        ...body,
    })
        .then((response) => response.json())
        .catch(handleError);

export const getBudgetRequst = (params) =>
    GET(`/client/campaigns/${params}/price/vocal`)
        // GET(`/client/customers/search?page=${params.page}&itemsPerPage=${params.pageSize}`)
        .then((response) => response.json())
        .catch(handleError);
