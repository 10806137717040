import {
  FETCH_CLIENT_TAGS,
  FETCH_CLIENT_TAGS_SUCCESS,
  FETCH_CLIENT_TAGS_ERROR,
  PAGE_SIZE,
  CLIENT_TAGS_PAGE_SIZE,
  FETCH_CLIENTS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
  CLIENTS_PAGE_SIZE
} from '../actions';

const INIT_STATE = {
    // clientTags: [],
    clientTagsPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CLIENT_TAGS_PAGE_SIZE
    },
    clientsPageInfo: {
      list: [],
      total: 0,
      page: 1,
      size: CLIENTS_PAGE_SIZE
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CLIENT_TAGS:
            return { ...state,  };
        case FETCH_CLIENT_TAGS_SUCCESS:
            return { ...state, clientTagsPageInfo: action.payload};
        case FETCH_CLIENT_TAGS_ERROR:
            return { ...state, };
        case FETCH_CLIENTS:
            return { ...state,  };
        case FETCH_CLIENTS_SUCCESS:
            return { ...state, clientsPageInfo: action.payload};
        case FETCH_CLIENTS_ERROR:
            return { ...state, };
        default: return { ...state };
    }
}