
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {

  GET_VOICE_MESSAGE,
  VOICE_MESSAGE_PAGE_SIZE,
  GET_AUDIO_MESSAGE,
  DELETE_VOICE_MESSAGE,
  UPDATE_MESSAGE_TITLE,
  GET_MESSAGE_CODE,
  GET_BUDGET
} from '../actions';

import {
  
  get_voice_message_success,
  get_voice_message_error,
  get_audio_message_success,
  get_audio_message_error,
  delete_voice_message_success,
  delete_voice_message_error,
  get_voice_message,
  update_message_title_success,
  update_message_title_error,
  get_message_code_success,
  get_message_code_error,
  get_budget_success,
  get_budget_error,
  showLoader,
  hideLoader
} from '../actions';

import {
  getVoiceMessage,
  audioMessageRequst,
  deleteVoicMessageRequst,
  updateMessageRequst,
  getMessageCode,
  getBudgetRequst
} from '../../services/api';

import { 
  showError,
  showInfo,
  showSuccess,
  showWarning 
} from '../../services/utils';

function* getVoiceMessageAsync({payload}) {
    // console.log(VOICE_MESSAGE_PAGE_SIZE)
    yield put(showLoader());
    payload.pageSize = VOICE_MESSAGE_PAGE_SIZE;
    // console.log(payload);
    try {
        const response = yield call( getVoiceMessage, payload );
        // console.log(response)

        if(response && response['hydra:member']) {
          yield put(hideLoader());
            yield put(get_voice_message_success(response['hydra:member'], payload.page, response['hydra:totalItems'], VOICE_MESSAGE_PAGE_SIZE ));
        }

    } catch (error) {
        yield put(hideLoader());
        yield put(get_voice_message_error(error['hydra:description']));
         // console.log(error)
        // showError('Sorry!', error['hydra:description']);
        // yield put(createUserError(error['hydra:description']));

    }
}


function* getAudioMessageAsync({payload}) {
    yield put(showLoader());
    try {
        const response = yield call( audioMessageRequst, payload );
        if(response && response.linkToFile) {
          yield put(hideLoader());
            yield put(get_audio_message_success(response.linkToFile));
        }

    } catch (error) {
        yield put(hideLoader());
        yield put(get_audio_message_error(error['hydra:description']));
        if(error && error.linkToFile) {
          yield put(get_audio_message_success(error.linkToFile));
        }

    }
}


function* delVoiceMessageAsync({payload}) {
    let id = payload && payload.id
    yield put(showLoader());
    try {
        const response = yield call( deleteVoicMessageRequst, id );
        // console.log(response)
        yield put (get_voice_message({page: payload.page}))
        yield put(hideLoader());
        // if(response && response['hydra:member']) {
        //     yield put(delete_voice_message_success(response['hydra:member'], payload.page, response['hydra:totalItems'], VOICE_MESSAGE_PAGE_SIZE ));
        // }

    } catch (error) {
        yield put(hideLoader());
        yield put(delete_voice_message_error(error['hydra:description']));
         // console.log(error)
        // showError('Sorry!', error['hydra:description']);
        // yield put(createUserError(error['hydra:description']));

    }
}

function* updateMessageAsync({payload}) {
    // console.log("update message",payload);
    const { data } = payload;
    try {
        yield put(showLoader());
        const response = yield call( updateMessageRequst, data);
        // console.log(response)
        if(response) {
          yield put(hideLoader());
            yield put(update_message_title_success(response));
            yield put (get_voice_message({page: data.page}))
        }

    } catch (error) {
        yield put(hideLoader());
        yield put(update_message_title_error(error['hydra:description']));
         // console.log(error)
        // showError('Sorry!', error['hydra:description']);
        // yield put(createUserError(error['hydra:description']));
    }
}


function* getMessageCodeAsync({payload}) {
    // console.log(payload)
    yield put(get_message_code_success({voiceCode: ''}));
    try {
        const response = yield call(getMessageCode, payload);
        // console.log(response)

        if(response) {
            yield put(get_message_code_success(response));
        }

    } catch (error) {
        yield put(get_message_code_error(error['hydra:description']));
         // console.log(error)
    }
}


function* getBudgetAsync({payload}) {
     console.log(payload)

    try {
        showLoader();
        const response = yield call(getBudgetRequst, payload);
         console.log(response)

        if(response) {
          hideLoader();
            yield put(get_budget_success(response));
        }

    } catch (error) {
      hideLoader();
        yield put(get_budget_error(error['hydra:description']));
         // console.log(error)
    }
}

export function* watchgetMessage() {
    yield takeEvery(GET_VOICE_MESSAGE, getVoiceMessageAsync);
}

export function* watchgetAudioMessage() {
    yield takeEvery(GET_AUDIO_MESSAGE, getAudioMessageAsync);
}

export function* watchdeleteAudioMessage() {
    yield takeEvery(DELETE_VOICE_MESSAGE, delVoiceMessageAsync);
}


export function* watchUpdateMessage() {
    yield takeEvery(UPDATE_MESSAGE_TITLE, updateMessageAsync);
}

export function* watchGetMessageCode() {
    yield takeEvery(GET_MESSAGE_CODE, getMessageCodeAsync);
}

export function* watchGetBudget() {
  yield takeEvery(GET_BUDGET, getBudgetAsync);
}


export default function* rootSaga() {
    yield all([
        fork(watchgetMessage),
        fork(watchgetAudioMessage),
        fork(watchdeleteAudioMessage),
        fork(watchUpdateMessage),
        fork(watchGetMessageCode),
        fork(watchGetBudget),

    ]);
}