import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_SALE_TAGS,
    UPDATE_SALE_TAG,
    PAGE_SIZE,
    DELETE_SALE_TAG,
    SALE_TAGS_PAGE_SIZE,
    CREATE_SALE_TAG,
    CREATE_SALE,
    FETCH_SALES,
    FETCH_DASHBOARD_SALES,
    SALES_PAGE_SIZE,
    DELETE_SALE,
    UPDATE_SALE,
} from '../actions';

import {
    createSaleSuccess,
    createSaleError,
    loadSalesSuccess,
    loadSalesError,
    loadSales,
    loadDashboardSalesSuccess,
    loadDashboardSalesError,
    showLoader,
    hideLoader,
} from '../actions';

import {
    showError,
    showInfo,
    showSuccess,
    showWarning,
} from '../../services/utils';

import {
    getSaleTagsRequest,
    updateSaleTagRequest,
    deleteSaleTagRequest,
    addSaleTagRequest,
    createSaleRequest,
    getSalesRequest,
    getDashboardSalesRequest,
    deleteSaleRequest,
    updateSaleRequest,
} from '../../services/api';

function* createSaleAsync({ payload }) {
    const { history, data, from, onSuccess } = payload;

    try {
        yield put(showLoader());
        const response = yield call(createSaleRequest, data);
        console.log('createSaleAsync response', response);
        if (response) {
            yield put(hideLoader());
            let couponMsg = response.couponMessage
                ? response.couponMessage
                : '';
            // let couponMsg = response.couponMessage ? '' : 'Un nouveau chèque est prêt à être édité pour ce client';
            // ' Un nouveau chèque est prêt à être édité pour ce client'
            showSuccess('Félicitations!', 'Vente créée! \r\n' + couponMsg);
            onSuccess();
            history.push(from);
            // window.location.reload();
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* updateSaleAsync({ payload }) {
    const { history, data, from, onSuccess } = payload;

    try {
        yield put(showLoader());
        const response = yield call(updateSaleRequest, data);

        if (response) {
            yield put(hideLoader());
            showSuccess('Félicitations!', 'Vente mise à jour!');
            onSuccess()
            history.push(from);
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

function* getSalesAsync({ payload }) {
    console.log('getSalesAsync payload', payload);
    payload.pageSize = SALES_PAGE_SIZE;
    try {
        yield put(showLoader());
        const response = yield call(getSalesRequest, payload);
        console.log('getSalesAsync response', response);

        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(
                loadSalesSuccess(
                    response['hydra:member'],
                    payload.page,
                    response['hydra:totalItems'],
                    SALE_TAGS_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadSalesError(error['hydra:description']));
    }
}

function* getDashboardSalesAsync({ payload }) {
    console.log('getDashboardSalesAsync payload', payload);
    const { startDate } = payload;
    payload.pageSize = SALES_PAGE_SIZE;

    let url = payload.startDate
        ? '&dateFrom=' + payload.startDate + '&dateTo=' + payload.endDate
        : '&period=' + payload.period;

    payload.urlParams = url;

    try {
        yield put(showLoader());
        const response = yield call(getDashboardSalesRequest, payload);

        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(
                loadDashboardSalesSuccess(
                    response['hydra:member'],
                    payload.page,
                    response['hydra:totalItems'],
                    SALE_TAGS_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadDashboardSalesError(error['hydra:description']));
    }
}

function* deleteSaleAsync({ payload }) {
    console.log(payload);
    try {
        yield put(showLoader());
        const response = yield call(deleteSaleRequest, payload.params.id);
        console.log(response);

        if (response) {
            yield put(hideLoader());
            yield put(loadSales({ page: payload.page }));
        }
    } catch (error) {
        yield put(hideLoader());
        showError('Désolé!', error['hydra:description']);
    }
}

export function* watchCreateSale() {
    yield takeEvery(CREATE_SALE, createSaleAsync);
}

export function* watchLoadSale() {
    yield takeEvery(FETCH_SALES, getSalesAsync);
}
export function* watchLoadDashboardSale() {
    yield takeEvery(FETCH_DASHBOARD_SALES, getDashboardSalesAsync);
}

export function* watchDeleteSale() {
    yield takeEvery(DELETE_SALE, deleteSaleAsync);
}

export function* watchUpdateSale() {
    yield takeEvery(UPDATE_SALE, updateSaleAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchCreateSale),
        fork(watchLoadSale),
        fork(watchLoadDashboardSale),
        fork(watchDeleteSale),
        fork(watchUpdateSale),
    ]);
}
