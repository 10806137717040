import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    CREATE_CHECQUE,
    CREATE_CHECQUE_SUCCESS,
    CREATE_CHECQUE_ERROR,
    FETCH_CHECQUES,
    FETCH_CHECQUES_ENVOYEE,
    FETCH_CHECQUES_SUCCESS,
    FETCH_CHECQUES_ERROR,
    DELETE_CHECQUE,
    UPDATE_CHECQUE,
    UPDATE_CHECQUES,
    CHECQUE_PAGE_SIZE,
    CHECQUE_TAGS_PAGE_SIZE,
    FETCH_CHECQUES_USED,
} from '../actions';

import {
    createChecqueSuccess,
    createChecqueError,
    loadChecquesSuccess,
    loadChecquesError,
    loadChecques,
    loadChecquesUsedSuccess,
    loadChecquesEnvoyeeSuccess,
    loadChecquesEnvoyeeError,
    showLoader,
    hideLoader,
    setSaleActiveClient,
} from '../actions';

import {
    showError,
    showInfo,
    showSuccess,
    showWarning,
} from '../../services/utils';

import {
    getChecquesRequest,
    getChecquesEnvoyeeRequest,
    getChecquesUsedRequest,
    updateChecqueStatusRequest,
} from '../../services/api';
import _ from 'lodash';

function* getChecquesAsync({ payload }) {
    try {
        yield put(showLoader());
        const response = yield call(getChecquesRequest, payload);
        console.log(response);
        if (response && response['hydra:member']) {
            yield put(hideLoader());
            yield put(
                loadChecquesSuccess(
                    response['hydra:member'],
                    payload.page,
                    response['hydra:totalItems'],
                    CHECQUE_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadChecquesError(error['hydra:description']));
    }
}

function* getChecquesEnvoyeeAsync({ payload }) {
    console.log('getChecquesEnvoyeeAsync payload', payload);
    const { startDate } = payload;

    payload.pageSize = CHECQUE_PAGE_SIZE;
    let url = payload.startDate
        ? '&dateFrom=' + payload.startDate + '&dateTo=' + payload.endDate
        : '&period=' + payload.period;

    payload.urlParams = url;

    try {
        yield put(showLoader());
        const response = yield call(getChecquesEnvoyeeRequest, payload);
        console.log('getChecquesEnvoyeeAsync response', response);

        // if(response && response['hydra:member']) {
        //     yield put(loadChecquesEnvoyeeSuccess(response['hydra:member'], payload.page, response['hydra:totalItems'], CHECQUE_PAGE_SIZE ));
        // }
        yield put(hideLoader());
        if (response && _.size(response) > 0) {
            const finalResponse = _.map(response, (res) => res)
            yield put(
                loadChecquesEnvoyeeSuccess(
                    finalResponse,
                    payload.page,
                    response.length,
                    CHECQUE_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadChecquesEnvoyeeError(error['hydra:description']));
    }
}

function* getChecquesUsedAsync({ payload }) {
    console.log(payload);
    const { startDate } = payload;

    payload.pageSize = CHECQUE_PAGE_SIZE;
    let url = payload.startDate
        ? '&dateFrom=' + payload.startDate + '&dateTo=' + payload.endDate
        : '&period=' + payload.period;

    payload.urlParams = url;

    try {
        yield put(showLoader());
        const response = yield call(getChecquesUsedRequest, payload);
        console.log(response);

        yield put(hideLoader());
        if (response && response.length > 0) {
            yield put(
                loadChecquesUsedSuccess(
                    response,
                    payload.page,
                    response.length,
                    CHECQUE_PAGE_SIZE
                )
            );
        }
    } catch (error) {
        yield put(hideLoader());
        yield put(loadChecquesEnvoyeeError(error['hydra:description']));
    }
}

function* checqueUpdateStatusAsync({ payload }) {
    console.log(payload);
    const { data: { redirectData = {}, ...restData } = {}, history } = payload;
    try {
        yield put(showLoader());
        const response = yield call(updateChecqueStatusRequest, restData);
        console.log(response);
        if (response) {
            yield put(hideLoader());
            // yield put(loadChecques({ page : payload.page}))
            yield put(setSaleActiveClient(''));

            history.push({
                pathname: '/newsale',
                // updateCouponProps: 'updated'
                payload: {
                    type: 'REDIRECT_SALE_IN_PROGRESS',
                    redirectData,
                },
            });
            // window.location.reload();
        }
    } catch (error) {
        yield put(hideLoader());
        // yield put(loadChecquesEnvoyeeError(error['hydra:description']));
    }
}

function* checquesUpdateStatusAsync({ payload }) {
    const {
        data: { redirectData = {}, checques = [], status = 'used' } = {},
        history,
    } = payload;
    try {
        yield put(showLoader());
        const [response] = yield all([
            ...checques.map((id) =>
                call(updateChecqueStatusRequest, { id, status })
            ),
        ]);
        console.log(response);
        if (response) {
            yield put(hideLoader());
            // yield put(loadChecques({ page : payload.page}))
            yield put(setSaleActiveClient(''));

            history.push({
                pathname: '/newsale',
                // updateCouponProps: 'updated'
                payload: {
                    type: 'REDIRECT_SALE_IN_PROGRESS',
                    redirectData,
                },
            });
            // window.location.reload();
        }
    } catch (error) {
        yield put(hideLoader());
        // yield put(loadChecquesEnvoyeeError(error['hydra:description']));
    }
}

export function* watchLoadChecque() {
    yield takeEvery(FETCH_CHECQUES, getChecquesAsync);
}

export function* watchLoadChecqueEnvoyee() {
    yield takeEvery(FETCH_CHECQUES_ENVOYEE, getChecquesEnvoyeeAsync);
}

export function* watchLoadChecqueUsed() {
    yield takeEvery(FETCH_CHECQUES_USED, getChecquesUsedAsync);
}

export function* watchChecqueUpdate() {
    yield takeEvery(UPDATE_CHECQUE, checqueUpdateStatusAsync);
}

export function* watchChecquesUpdate() {
    yield takeEvery(UPDATE_CHECQUES, checquesUpdateStatusAsync);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadChecque),
        fork(watchLoadChecqueEnvoyee),
        fork(watchLoadChecqueUsed),
        fork(watchChecqueUpdate),
        fork(watchChecquesUpdate),
    ]);
}
