import {
  FETCH_NEWS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_ERROR
} from '../actions';

const INIT_STATE = {
    newsList: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS:
            return { ...state, loading:true, error: '' };
        case FETCH_NEWS_SUCCESS:
            return { ...state, loading:false, newsList: action.payload, error: '' };
        case FETCH_NEWS_ERROR:
            return { ...state, loading:false, newsList: [], error: action.payload.message };
        default: return { ...state };
    }
}