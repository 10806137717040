import {
    CREATE_CHECQUE,
    CREATE_CHECQUE_SUCCESS,
    CREATE_CHECQUE_ERROR,
    FETCH_CHECQUES,
    FETCH_CHECQUES_SUCCESS,
    FETCH_CHECQUES_ERROR,
    FETCH_CHECQUES_ENVOYEE,
    FETCH_CHECQUES_ENVOYEE_SUCCESS,
    FETCH_CHECQUES_ENVOYEE_ERROR,
    DELETE_CHECQUE,
    UPDATE_CHECQUE,
    FETCH_CHECQUES_USED,
    FETCH_CHECQUES_USED_SUCCESS,
    UPDATE_CHECQUES,
} from '../actions';

export const createChecque = (data, history, from) => ({
    type: CREATE_CHECQUE,
    payload: { data, history, from },
});
export const createChecqueSuccess = (user) => ({
    type: CREATE_CHECQUE_SUCCESS,
    payload: user,
});
export const creatSChecqueError = (message) => ({
    type: CREATE_CHECQUE_ERROR,
    payload: { message },
});

export const loadChecques = (params) => ({
    type: FETCH_CHECQUES,
    payload: params,
});
export const loadChecquesSuccess = (list, page, total, size) => ({
    type: FETCH_CHECQUES_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});
export const loadChecquesError = (message) => ({
    type: FETCH_CHECQUES_ERROR,
    payload: { message },
});

export const loadChecquesEnvoyee = (params) => ({
    type: FETCH_CHECQUES_ENVOYEE,
    payload: params,
});
export const loadChecquesUsed = (params) => ({
    type: FETCH_CHECQUES_USED,
    payload: params,
});
export const loadChecquesUsedSuccess = (list, page, total, size) => ({
    type: FETCH_CHECQUES_USED_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});
export const loadChecquesEnvoyeeSuccess = (list, page, total, size) => ({
    type: FETCH_CHECQUES_ENVOYEE_SUCCESS,
    payload: { list: list, page: page, total: total, size: size },
});
export const loadChecquesEnvoyeeError = (message) => ({
    type: FETCH_CHECQUES_ENVOYEE_ERROR,
    payload: { message },
});

export const deleteChecque = (params, page) => ({
    type: DELETE_CHECQUE,
    payload: { params, page },
});

export const updateChecque = (data, history) => ({
    type: UPDATE_CHECQUE,
    payload: { data, history },
});

export const updateChecques = (data, history) => ({
    type: UPDATE_CHECQUES,
    payload: { data, history },
});
