
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import {
    CREATE_USER,
    RECOVER_PASSWORD,
    LOAD_USER
} from '../actions';

import {
    createUserSuccess,
    createUserError,
    loadUserSuccess,
    loadUserError,
    showLoader,
    hideLoader
} from '../actions';

import {
  createUserRequest,
  passwordRecoveryRequest,
  getUserProfileRequest
} from '../../services/api';

import { 
  showError,
  showInfo,
  showSuccess,
  showWarning 
} from '../../services/utils';

function* createUserAsync({ payload }) {
    const { history, data } = payload;
    try {
        yield put(showLoader());
        const response = yield call(createUserRequest, data);
        if(response) {
            yield put(hideLoader());
            yield put(createUserSuccess(response));
            showSuccess('Félicitations!', 'Votre compte a été créé avec succès!');
            history.push('/');
        }

        // if(response && response.ok) {
        //     const resp = response.json();

        //     console.log()
        //     response.json().then(
        //     function(resp) {
        //         // yield put(createUserSuccess(resp));
        //     })
        // } else {
        //     response.json().then(
        //         function(err) {
        //             // yield put(createUserError(err['hydra:description']));
        //         }
        //     )
        // }

    } catch (error) {
        yield put(hideLoader());
        showError('Sorry!', error['hydra:description']);
        yield put(createUserError(error['hydra:description']));

    }
}

function* passwordRecoverAsync({ payload }) {
    const { history, data } = payload;
    try {
        yield put(showLoader());
        const response = yield call(passwordRecoveryRequest, data);
        if(response) {
            yield put(hideLoader());
            // showSuccess('Congratulations!', 'Your request has been succesfully received!');
            showSuccess('Félicitations!', response);
        }

    } 
    catch (error) {
        yield put(hideLoader());
        showError('Sorry!', error['hydra:description']);
    }
}

function* loadUserAsync() {
    try {
        yield put(showLoader());
        const response = yield call(getUserProfileRequest);
        if(response && response.id) {
            yield put(hideLoader());
            yield put(loadUserSuccess(response));
            localStorage.setItem('icust_user', JSON.stringify(response));
        }

    } catch (error) {
        yield put(hideLoader());
        // yield put(loadUserError(error['hydra:description']));
        showError('Sorry!', error['hydra:description']);

    }
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUserAsync);
}

export function* watchPasswordRecover() {
    yield takeEvery(RECOVER_PASSWORD, passwordRecoverAsync);
}

export function* watchLoadUser() {
    yield takeEvery(LOAD_USER, loadUserAsync);
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateUser),
        fork(watchPasswordRecover),
        fork(watchLoadUser)
    ]);
}