import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR
} from '../actions';

const INIT_STATE = {
    // profile: JSON.parse(localStorage.getItem('icust_user')) || null,
    profile: null,
    loading: false,
    error: '',
    createUserError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_USER:
            return { ...state, error: '', createUserError: '' };
        case CREATE_USER_SUCCESS:
            return { ...state, user: action.payload, createUserError: '' };
        case CREATE_USER_ERROR:
            return { ...state, user: null, createUserError: action.payload.message };
        // case LOAD_USER:
        //     return { ...state, user: null };
        case LOAD_USER_SUCCESS:
            return { ...state, profile: action.payload };
        case LOAD_USER_ERROR:
            return { ...state, user: null };
        default: return { ...state };
    }
}