import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR
} from '../actions';

export const loginUser = (cred, history, rememberMe) => ({
  type: LOGIN_USER,
  payload: { cred, history, rememberMe }
});
export const loginUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload: payload
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {}
});
export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
  payload: {}
});
export const logoutUserError = (message) => ({
  type: LOGOUT_USER_ERROR,
  payload: { message }
});

export const refreshToken = (token) => ({
  type: REFRESH_TOKEN,
  payload: { token }
});
export const refreshTokenSuccess = (payload) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: payload
});
export const refreshTokenError = (message) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: { message }
});
