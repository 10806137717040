import {
  CREATE_SEGMENT,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_ERROR,
  FETCH_SEGMENTS,
  FETCH_SEGMENTS_SUCCESS,
  FETCH_SEGMENTS_ERROR,
  DELETE_SEGMENT,
  UPDATE_SEGMENT
} from '../actions';



export const createSegment = (data, history, from) => ({
  type: CREATE_SEGMENT,
  payload: { data, history, from }
});
export const createSegmentSuccess = (user) => ({
  type: CREATE_SEGMENT_SUCCESS,
  payload: user
});
export const creatSegmenttError = (message) => ({
  type: CREATE_SEGMENT_ERROR,
  payload: { message }
});

export const loadSegments = (params) => ({
  type: FETCH_SEGMENTS,
  payload: { page: params.page }
});
export const loadSegmentsSuccess = (list, page, total, size) => ({
  type: FETCH_SEGMENTS_SUCCESS,
  payload: { list: list, page: page, total: total, size: size }
});
export const loadSegmentsError = (message) => ({
  type: FETCH_SEGMENTS_ERROR,
  payload: { message }
});

export const deleteSegment = (params, page) => ({
  type: DELETE_SEGMENT,
  payload: { params, page }
});

export const updateSegment = (data, history, from) => ({
  type: UPDATE_SEGMENT,
  payload: { data, history, from }
});